<template>
  <div
    v-if="schoolStore.data.id"
    class="max-w-full overflow-auto flex flex-col gap-5 h-full">
    <!-- Page header -->
    <div class="flex justify-between items-center">
      <span class="flex gap-2 items-center text-lg">
        <!-- <RouterLink :to="{ name: 'school-list' }">
          <span class="cursor-pointer"><</span>
        </RouterLink> -->
        {{ schoolStore.data.name }}
      </span>

      <div
        class="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
        <PVButton
          :label="`${t('users.add_new_users')}`"
          @click="userManager?.open()"/>
        <PVButton
          :label="`${t('users.add_new_group')}`"
          @click="groupManager?.open({})"/>
      </div>
    </div>

    <PVTabMenu v-model:active-index="activeTab" :model="tabs" />
    <Transition name="slide">
      <div
        v-if="activeTab === TABS_INDEX.groups"
        class="flex flex-1 flex-col gap-5 pt-2">
        <div class="flex items-center gap-2">
          <SearchInput
            v-model="searchGroups"
            placeholder="חיפוש מהיר"
            :style="'neutral'"
            @reset-search="searchGroups = ''"/>
          <span class="text-secondary-800 text-xs">
            {{ groups.length ?? 0 }} {{ t('groups') }}
          </span>
        </div>
        <ScrollableContainer :mirrored="false">
          <div
            class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
            <GroupListItem
              v-for="group in groups"
              :key="group.id"
              :item="group"
              class="cursor-pointer"
              @edit-group="
                () =>
                  groupManager?.open({
                    groupId: group.id,
                    readonlyMode: group.sis_id,
                  })
              "
              @sync="() => schoolStore.syncGroup(group)"/>
          </div>
          <div v-if="!groups.length">
            <span class="text-secondary-800 text-sm">לא נמצאו קבוצות</span>
          </div>
        </ScrollableContainer>
      </div>
    </Transition>
    <Transition name="slide">
      <BaseTable
        v-if="activeTab === TABS_INDEX.students"
        :headers="studentsTableHeaders"
        :items="students"
        :label="t('students')"
        include-search>
        <template #actions="{ data }">
          <Pencil
            :size="16"
            class="hover:text-primary cursor-pointer"
            @click="userManager?.open(data.id)"/>
        </template>
      </BaseTable>
    </Transition>
    <Transition name="slide">
      <BaseTable
        v-if="activeTab === TABS_INDEX.teachers"
        :headers="teachersTableHeaders"
        :items="teachers"
        :label="t('teachers')"
        include-search>
        <template #actions="{ data }">
          <Pencil
            :size="16"
            class="hover:text-primary cursor-pointer"
            @click="userManager?.open(data.id)"/>
        </template>
      </BaseTable>
    </Transition>
  </div>

  <GroupManager ref="groupManager" />
  <UserManager ref="userManager" />
</template>

<script setup lang="ts">
import BaseTable from '/@/views/playground/partials/BaseTable.vue';
import GroupListItem from '/@/views/playground/partials/GroupListItem.vue';
import { ref, watch, watchEffect, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useSchoolStore } from '/@/views/playground/school-store';
import UserManager from '/@/views/playground/drawers/UserManager.vue';
import GroupManager from '/@/views/playground/drawers/GroupManager.vue';
import { Pencil } from 'lucide-vue-next';
import { useNavigation } from '@amit/layout';
import { SearchInput } from '@amit/foundation';
import ScrollableContainer from '/@/views/playground/partials/ScrollableContainer.vue';
import PVButton from 'primevue/button';
import PVTabMenu from 'primevue/tabmenu';

const schoolStore = useSchoolStore();
schoolStore.init().then(() => {});

const userManager = ref(null);
const groupManager = ref(null);
const searchGroups = ref('');

const route = useRoute();

const messages = {
  he: {
    students: 'תלמידים',
    teachers: 'מורים',
    groups: 'קבוצות',
    headers: {
      number: '#',
      fullName: 'שם מלא',
      gender: 'מגדר',
      class: 'כיתה',
      username: 'שם משתמש',
    },
    gender: {
      male: 'זכר',
      female: 'נקבה',
    },
  },
};
const { t } = useI18n({ messages });

watchEffect(() => {
  schoolStore.fetch(route.params.school);
});

const students = computed(() => {
  return schoolStore.students?.map(student => ({
    ...student,
    gender: student.gender ? t('gender.' + student.gender) : '----',
  }));
});

const teachers = computed(() => {
  return schoolStore.teachers?.map(teacher => ({
    ...teacher,
    gender: teacher.gender ? t('gender.' + teacher.gender) : '----',
  }));
});

const tabs = computed(() => [
  { label: `${t('teachers')} (${teachers.value.length})` },
  { label: `${t('students')} (${students.value.length})` },
  { label: `${t('groups')} (${schoolStore.groups.length})` },
]);

const TABS_INDEX = {
  teachers: 0,
  students: 1,
  groups: 2,
};

const activeTab = ref(0);

const teachersTableHeaders = ref([
  {
    title: t('headers.fullName'),
    align: 'start',
    key: 'fullName',
    sortable: true,
    style: 'width: 6rem',
  },
  // { title: t('headers.gender'), align: 'start', key: 'gender', sortable: true },
  {
    title: t('headers.username'),
    align: 'start',
    key: 'username',
    style: 'width: 10rem',
  },
]);
const studentsTableHeaders = ref([
  {
    title: t('headers.fullName'),
    align: 'start',
    key: 'fullName',
    sortable: true,
    style: 'width: 6rem',
  },
  {
    title: t('headers.gender'),
    align: 'start',
    key: 'gender',
    sortable: true,
    style: 'width: 3rem',
  },
  {
    title: t('headers.class'),
    align: 'start',
    key: 'class',
    sortable: true,
    style: 'width: 3rem',
  },
  {
    title: t('headers.username'),
    align: 'start',
    key: 'username',
    style: 'width: 10rem',
  },
]);

useNavigation(
  computed({
    get() {
      return schoolStore.schools.map(school => {
        return {
          text: school.name,
          to: {
            name: 'school-home',
            params: { school: school.id },
          },
        };
      });
    },
    set() {},
  }),
);

const filter = (array, keys, value) => {
  return array?.filter(item => {
    return keys.some(key => {
      return item[key].includes(value);
    });
  });
};

const groups = computed(() => {
  return filter(
    schoolStore.groups.map(item => {
      return {
        ...item,
        teacher:
          item.teachers?.find(({ id }) => id === item.owner_id)?.fullName ??
          '----',
      };
    }),
    ['name', 'teacher'],
    searchGroups.value,
  );
});

watch(activeTab, () => (searchGroups.value = ''));
</script>

<style scoped>
.vue-tags-input {
  padding: 5px;
  width: 100%;
}
</style>
